<template>
  <div class="custom-select-filter">
    <div v-if="params.filterName" class="custom-select-filter__title">
      {{ params.filterName }}
    </div>
    <div>
      <custom-input v-model="filterFrom" type="date" :inputWidth="80" :labelWidth="20" label="От" name="dateFrom"/>
			<custom-input v-model="filterTo" type="date" :inputWidth="80" :labelWidth="20" label="До" name="dateFrom"/>
    </div>
    <button v-if="filterFrom || filterTo" @click="filterFrom = ''; filterTo = ''" class="btn btn-w btn-sm">Очистить</button>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import CustomInput from '../../Forms/Fields/CustomInput.vue';
export default {
  components: { CustomInput },
  setup(props) {
    const filterFrom = ref(null), filterTo = ref(null);

    const doesFilterPass = (params) => {
      const { api, colDef, column, columnApi, context } = props.params;
      const { node } = params;

      let passed = false;

      const value = Date.parse(props.params.valueGetter({
        api,
        colDef,
        column,
        columnApi,
        context,
        data: node.data,
        getValue: (field) => node.data[field],
        node,
      }));

      if (value >= (Date.parse(filterFrom.value) || 0) && value <= (Date.parse(filterTo.value) || Number.MAX_SAFE_INTEGER)) {
        passed = true;
      }

      return passed;
    };

    const isFilterActive = () => {
      return (filterFrom.value != null && filterFrom.value !== "") || (filterTo.value != null && filterTo.value !== "");
    };

    const getModel = () => {
      if (!isFilterActive()) {
        return null;
      }

      return { value: filterFrom.value };
    };

    const setModel = (model) => {
      filterFrom.value = model == null ? null : model.value;
    };

    watch([filterTo, filterFrom], () => {
      props.params.filterChangedCallback();
    });

    return {
      filterTo, filterFrom,
      doesFilterPass,
      isFilterActive,
      getModel,
      setModel,
    };
  },
};
</script>

<style lang="scss" scoped>
.custom-select-filter {
  padding: 5px 5px 15px 20px;
  background-color: white;
  border: 1px solid var(--cream-color);
  border-radius: 3px;

  .form-group {
    min-width: 350px;
    margin: 10px 0;
  }

  &__title {
    font-size: 15px;
    font-weight: 400;
    padding-top: 8px;
  }
}
</style>


export const getTestStatus = (staff) => {
	if (!staff.last_test_passing) {
		return "Тест не выдан";
	} else {
		const status = staff.last_test_passing.status;
		if (
			status === "questionnaire" ||
			status === "instruction" ||
			status === "new"
		) {
			return "Тест не пройден";
		} else if (status === "in_progress") {
			return "В процессе прохождения";
		} else {
			return "Тест завершен";
		}
	}
};

<template>
  <Popper class="status-popper" hover arrow :content="params.value">
    <div>
      <div class="status-icon" v-if="params.value === 'Тест не выдан'">
        <img src="@/assets/images/icons/clock1.svg" alt="Статус:Тест не выдан" />
      </div>
      <div class="status-icon" v-if="params.value === 'Тест не пройден'">
        <img src="@/assets/images/icons/clock2.svg" alt="Статус:Тест не пройден" />
      </div>
      <div class="status-icon" v-if="params.value === 'В процессе прохождения'">
        <img src="@/assets/images/icons/clock3.svg" alt="Статус:В процессе прохождения" />
      </div>
      <div class="status-icon" v-if="params.value === 'Тест завершен'">
        <img src="@/assets/images/icons/clock4.svg" alt="Статус:Тест завершен" />
      </div>
    </div>
  </Popper>
</template>

<script>
export default {
  name: "status-renderer",
};
</script>

<style lang="scss" scoped>
.status-icon {
  width: 22px;
  height: 22px;
  margin: auto;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.status-popper{
    display: block;
    margin: auto !important;
    font-size: 14px;
    cursor: help;
}
</style>



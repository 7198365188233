export default {
	name: "Имя",
	onlineFrom: "Дата последней авторизации",
	report: "Тип отчета",
	position: "Уровень должности",
	role: "Роль",
	sendPassingDate: "Дата отправки теста",
	status: "Статус",
	passingDate: "Дата прохождения",
	class: "Обучение",
	eiToken: "Зайти в кабинет"
}
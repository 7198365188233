<template>
  <div class="position--relative">
    <div class="action-block">
      <div class="action-block__label">1. Выберите тип отчета</div>
      <custom-select
        :inputWidth="100"
        :options="[{ id: '0', text: 'Не выбран' }, ...options]"
        name="reportType"
        v-model="reportType"
      />
    </div>
    <div class="action-block">
      <div class="action-block__label">
        2. Укажите будут ли отчеты отправлены автоматически
      </div>
      <custom-checkbox
        name="automatic"
        :disabled="reportType === 'empty'"
        v-model="automaticGiving"
        label="Выдать отчеты автоматически после прохождения"
      />
    </div>
    <div class="action-block">
      <div class="action-block__label">3. Нажмите "Отправить"</div>
      <div class="w-full mt-5">
        <button @click="givePassing" class="btn btn-w btn-sm">Отправить</button>
      </div>
    </div>
    <div v-if="isPreloader" class="btn-preloader">
      <mini-preloader />
    </div>
  </div>
</template>

<script>
import { computed, ref, toRef, toRefs } from "@vue/reactivity";
import { useStore } from "vuex";
import { notify } from "@kyvg/vue3-notification";
import CustomCheckbox from "../../../components/Forms/Fields/CustomCheckbox.vue";
import CustomSelect from "../../../components/Forms/Fields/CustomSelect.vue";
import MiniPreloader from "../../../components/Technical/MiniPreloader.vue";
export default {
  name: "giving-test-mini",
  components: {
    CustomCheckbox,
    CustomSelect,
    MiniPreloader,
  },
  props: {
    users: {
      type: Array,
      required: false,
    },
    groupId: {
      required: true,
    },
    checkedList: {
      type: Array,
      required: true,
    },
    gridApi: {
      required: false,
    },
    isSingle: {
      type: Boolean,
      default: false,
    },
    testOptionsCustom: {
      type: Array,
    },
  },
  setup(props, context) {
    const customOptions = toRef(props, "testOptionsCustom");
    const options = computed(() => {
      if (customOptions.value?.length) return customOptions.value;
      return [];
    });

    const store = useStore();
    const { users, groupId, checkedList, isSingle, gridApi } = toRefs(props);
    const automaticGiving = ref(false),
      reportType = ref("0"),
      isPreloader = ref(false);
    const givePassing = () => {
      if (reportType.value !== "0") {
        isSingle.value ? giveSingle() : giveMultiple();
      } else {
        notify({
          title: "Выберите тип отчета",
          type: "warning",
        });
      }
    };

    const giveMultiple = () => {
      if (
        !users.value.every(
          (staff) =>
            !checkedList.value.includes(staff.id) ||
            !staff.last_test_passing ||
            (staff.last_test_passing &&
              staff.last_test_passing.status === "finished")
        )
      ) {
        notify({
          title:
            "Нельзя выдать сотрудникам, у которых не завершено прохождение",
          type: "warning",
        });
      } else {
        isPreloader.value = true;
        store
          .dispatch("group/giveStaffPassings", {
            id: groupId.value,
            staffIds: checkedList.value,
            reportType: reportType.value,
            count: checkedList.value.length,
          })
          .then(() => {
            allowView();
            store.dispatch("group/getGroupQuotas", groupId.value);
            gridApi.value.forEachNode((node) =>
              node.setSelected(checkedList.value.includes(node.data.id))
            );
          })
          .finally(() => (isPreloader.value = false));
      }
    };

    const giveSingle = () => {
      isPreloader.value = true;
      store
        .dispatch("group/giveStaffPassings", {
          id: groupId.value,
          reportType: reportType.value,
          staffIds: checkedList.value,
          count: 1,
        })
        .then(() => {
          allowView();
          store.dispatch("group/getGroupQuotas", groupId.value);
        })
        .finally(() => (isPreloader.value = false));
    };

    const allowView = () => {
      if (automaticGiving.value) {
        store.dispatch("group/allowToViewReport", {
          id: groupId.value,
          staffIds: checkedList.value,
        });
        automaticGiving.value = false;
      }
    };

    return {
      givePassing,
      automaticGiving,
      reportType,
      isPreloader,
      options,
    };
  },
};
</script>

<style>
</style>
import reportTranslate from "./dictionaries/reportTranslate";


export default function () {

    const reportDictionary = reportTranslate;

    const getTestCount = (tests, reportType) => {
        if (!tests) return "-";
        else {
            const testObj = tests.find((obj) => obj.report_type == reportType);
            if (testObj) return testObj.count;
            else return 0;
        }
    }
    const getCourseCount = (courses, external_id) => {
        if (!courses) return "-";
        else {
            const courseObject = courses.find(
                (obj) => obj.external_id == external_id
            );
            if (courseObject) return courseObject.count;
            else return 0;
        }
    };

    const getTrajectoriesCount = (trajectories, external_id) => {
        if (!trajectories) return "-";
        else {
            const trajectoriesObject = trajectories.find(
                (obj) => obj.external_id == external_id
            );
            if (trajectoriesObject) return trajectoriesObject.count;
            else return 0;
        }
    };

    const getTestList = (quota) => {
        const typesSet = new Set(),
            testOptions = [];
        if (quota.free) {
            quota.free.forEach((test) => {
                if (!typesSet.has(test.report_type)) {
                    typesSet.add(test.report_type);
                    testOptions.push({ id: test.report_type, text: reportDictionary[test.report_type] });
                }
            });
        }
        if (quota.issued) {
            quota.issued.forEach((test) => {
                if (!typesSet.has(test.report_type)) {
                    typesSet.add(test.report_type);
                    testOptions.push({ id: test.report_type, text: reportDictionary[test.report_type] });
                }
            });
        }
        return testOptions;
    };
    const getCourseList = (quota) => {
        const idsSet = new Set(),
            newCourseOptions = [];
        if (quota.value.course.free) {
            quota.value.course.free.forEach((course) => {
                if (!idsSet.has(course.external_id)) {
                    idsSet.add(course.external_id);
                    newCourseOptions.push({ id: course.external_id, text: course.name });
                }
            });
        }
        if (quota.value.course.issued) {
            quota.value.course.issued.forEach((course) => {
                if (!idsSet.has(course.external_id)) {
                    idsSet.add(course.external_id);
                    newCourseOptions.push({ id: course.external_id, text: course.name });
                }
            });
        }
        return newCourseOptions;
    };

    const getTrajectoriesList = (quota) => {
        const idsSet = new Set(),
            newTrajectoriesOptions = [];
        if (quota.free) {
            quota.free.forEach((trajectories) => {
                if (!idsSet.has(trajectories.external_id)) {
                    idsSet.add(trajectories.external_id);
                    newTrajectoriesOptions.push({ id: trajectories.external_id, text: trajectories.name });
                }
            });
        }
        if (quota.issued) {
            quota.issued.forEach((trajectories) => {
                if (!idsSet.has(trajectories.external_id)) {
                    idsSet.add(trajectories.external_id);
                    newTrajectoriesOptions.push({ id: trajectories.external_id, text: trajectories.name });
                }
            });
        }
        return newTrajectoriesOptions;
    };

    const getCourseListGroup = (quota) => {
        const idsSet = new Set(),
            newCourseOptions = [];
        if (quota.free) {
            quota.free.forEach((course) => {
                if (!idsSet.has(course.external_id)) {
                    idsSet.add(course.external_id);
                    newCourseOptions.push({ id: course.external_id, text: course.name, source: course.source });
                }
            });
        }
        if (quota.issued) {
            quota.issued.forEach((course) => {
                if (!idsSet.has(course.external_id)) {
                    idsSet.add(course.external_id);
                    newCourseOptions.push({ id: course.external_id, text: course.name, source: course.source });
                }
            });
        }

        return newCourseOptions;
    };

    return {
        getTestCount, getCourseCount, getCourseList, getCourseListGroup, getTestList, getTrajectoriesList, getTrajectoriesCount
    }
}
<template>
  <Popper class="columns-control-popper">
    <button class="btn btn-w columns-control">
      <img src="@/assets/images/icons/settings.svg" alt="Иконка настроек" />
    </button>
    <template #content>
      <custom-checkbox
        v-for="(value, key) in visibleColumns"
        :key="key"
        v-model="visibleColumns[key]"
        :checked="visibleColumns[key]"
        :label="columnsDictionary[key]"
      />
    </template>
  </Popper>
</template>

<script>
import { reactive, toRefs, watch } from "@vue/runtime-core";
import visibleColumnsDictionary from "../../mixins/dictionaries/visibleColumnsDictionary";
import CustomCheckbox from "../Forms/Fields/CustomCheckbox.vue";
export default {
  components: { CustomCheckbox },
  name: "control-visible-columns",
  props: {
    columnApi: {
      required: true,
    },
    name: {
      type: String,
    },
  },
  setup(props) {
    const columnsDictionary = visibleColumnsDictionary;
    const { name, columnApi } = toRefs(props);
    const visibleColumns = reactive({
      position: true,
      passingDate: true,
      sendPassingDate: true,
      name: true,
      status: true,
      class: true,
      report: true,
      onlineFrom: true,
      role: true,
      eiToken: true,
    });

    watch(visibleColumns, () => {
      if (columnApi.value) {
        for (let field in visibleColumns) {
          columnApi.value.setColumnVisible(field, visibleColumns[field]);
        }
        localStorage.setItem(name.value, JSON.stringify(visibleColumns));
      }
    });

    watch(name, (nextValue, prevValue) => {
      if ((!prevValue, nextValue)) {
        let options = localStorage.getItem(name.value);
        if (options) {
          options = JSON.parse(options);
          for (let key in visibleColumns) {
            visibleColumns[key] = options[key] ? options[key] : false;
          }
        }
      }
    });

    return {
      columnsDictionary,
      visibleColumns,
    };
  },
};
</script>

<style scoped lang="scss">
.columns-control {
  font-size: 16px;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  padding: 9px;

  img {
    height: 100%;
    width: 100%;
  }

  @media (max-width: 1480px) {
    margin-bottom: 10px;
  }
}
</style>
export default {
	value: [
		{
			field: "id",
			hide: true,
		},
		{
			field: "check",
			headerName: "",
			checkboxSelection: true,
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			minWidth: 50,
			maxWidth: 50,
			flex: 0,
		},
		{
			field: "name",
			headerName: "Имя",
			wrapText: true,
			sortable: true,
			minWidth: 255,
		},
		{
			field: "onlineFrom",
			headerName: "Дата последней авторизации",
			minWidth: 180,
		},
		{
			field: "report",
			headerName: "Тип отчета",
			minWidth: 200,
		},
		{ field: "position", headerName: "Уровень должности", minWidth: 200 },
		{
			field: "role",
			headerName: "Роль",
			minWidth: 150,
			maxWidth: 150,
			flex: 0,
			filter: "CustomSelectFilter",
			filterParams: {
				filterName: "Поиск по роли",
				selectOptions: [
					"Суперадмин",
					"Куратор",
					"Сотрудник",
					"Администратор",
				],
			},
		},
		{
			field: "sendPassingDate",
			wrapText: true,
			headerName: "Дата отправки теста",
			minWidth: 180,
			sortable: true,
		},
		{
			field: "status",
			headerName: "Статус",
			width: 90,
			flex: 0,
			cellRenderer: "StatusRenderer",
		},
		{
			field: "passingDate",
			wrapText: true,
			headerName: "Дата прохождения",
			minWidth: 210,
			sortable: true,
			filter: "CustomDateFilter",
			filterParams: {
				filterName: "Время прохождения теста",
			},
		},
		{
			field: "class",
			headerName: "Обучение",
			width: 115,
			flex: 0,
			cellRenderer: "ClassRenderer",
		},
		{
			field: "eiToken",
			headerName: "Зайти под учётную запись",
			cellRenderer: "AuthorizationRenderer",
			flex: 0,
		},
		{
			field: "action",
			width: 50,
			headerName: "",
			flex: 0,
			cellRenderer: "ActionsRenderer",
		},
		{
			field: "edit",
			width: 70,
			headerName: "",
			flex: 0,
			cellRenderer: "EditRenderer",
			cellRendererParams: {
				source: "staff",
			},
		},
	],
}
import {notify} from "@kyvg/vue3-notification";
import {useStore} from "vuex";

export default function () {
    const store = useStore();

    const giveTests = (quota, groupId) => {
        if (quota.reportType == "Выберите тип отчета") {
            notify({type: "warning", title: "Выберите тип отчета"});

        } else if (quota.testCount == 0 || quota.testCount < 0) {
            notify({type: "warning", title: "Введите количество тестов"});
        } else {
            store.dispatch("company/giveGroupPassings", {
                id: groupId,
                reportType: quota.reportType,
                count: quota.testCount,
            });
        }
    }
    const giveCourses = (quota, groupId) => {
        if (quota.course == "Выберите курс") {
            notify({type: "warning", title: "Выберите один из курсов"});
        } else if (quota.courseCount == 0 || quota.testCount < 0) {
            notify({type: "warning", title: "Введите количество курсов"});
        } else
            store.dispatch("company/giveGroupCourses", {
                id: groupId,
                courseCode: quota.course,
                count: quota.courseCount,
            });
    }
    const takeTests = (quota, groupId) => {
        if (quota.reportType == "Выберите тип отчета") {
            return Promise.reject("Не выбран тип отчета");
        } else if (quota.testCount == 0 || quota.testCount < 0) {
            return Promise.reject("Нет количества тестов");
        } else
            return store.dispatch("company/takeGroupPassings", {
                id: groupId,
                reportType: quota.reportType,
                count: quota.testCount,
            });
    }
    const takeCourses = (quota, groupId) => {
        if (quota.course == "Выберите курс") {
            return Promise.reject("Не выбран курс");
        } else if (quota.courseCount == 0 || quota.testCount < 0) {
            return Promise.reject("Нет количества курсов");
        } else {
            return store.dispatch("company/takeGroupCourses", {
                id: groupId,
                courseCode: quota.course,
                count: quota.courseCount,
            });
        }
    };

    const giveTrajectories = (quota, groupId) => {
        if (quota.trajectoriesCount == 0 || quota.testCount < 0) {
            notify({type: "warning", title: "Введите количество траекторий"});
        } else
            store.dispatch("company/giveGroupTrajectories", {
                id: groupId,
                count: quota.trajectoriesCount,
            });
    }
    const takeTrajectories = (quota, groupId) => {
        if (quota.trajectoriesCount == 0 || quota.testCount < 0) {
            return Promise.reject("Нет количества траекторий");
        } else {
            return store.dispatch("company/takeGroupTrajectories", {
                id: groupId,
                count: quota.trajectoriesCount,
            });
        }
    };

    return {
        takeCourses, takeTests, giveCourses, giveTests, giveTrajectories, takeTrajectories
    }
}
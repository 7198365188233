<template>
    <div class="card group-passing-table">
        <div class="card-header">
            <div class="card-title">
                <span v-if="group">{{ group.name }}</span> <br/>
                <span class="users-count" v-if="group && group.users"
                >Количество участников: {{ group.users.length }}</span
                >
            </div>
            <div class="tools">
                <button
                        v-if="getRight('downloadReports')"
                        @click="getReports"
                        class="btn btn-dark"
                >
                    Скачать все отчеты
                    <i class="fas fa-box-archive"></i>
                </button>
                <router-link
                        :to="`/group/edit/${route.params.id}`"
                        class="btn btn-accent"
                >Редактировать
                </router-link>
            </div>
        </div>
        <div class="card-header card-header-divider justify-content-between">
            <div class="flex justify-content-start align-item-center control-btns">
                <control-visible-columns :name="columnsName" :columnApi="columnApi"/>
                <Popper placement="bottom" arrow class="action-popper">
                    <button
                            :disabled="!checkedList.length"
                            class="btn btn-w btn-sm action-btn"
                    >
                        Выдать тесты
                    </button>
                    <template #content>
                        <giving-tests-mini
                                v-if="group"
                                :users="group.users"
                                :groupId="group.id"
                                :gridApi="gridApi"
                                :checkedList="checkedList"
                                :testOptionsCustom="testOptions"
                                @clearChecked="clearChecked"
                        />
                    </template>
                </Popper>

                <button
                        class="btn btn-w btn-sm action-btn"
                        @click="takePassing"
                        :disabled="!checkedList.length"
                >
                    Забрать тесты
                </button>
                <button
                        @click="remindAboutTest"
                        :disabled="!checkedList.length"
                        class="btn btn-accent btn-sm"
                >
                    Напомнить о прохождении
                </button>
                <button
                        @click="sendReports"
                        :disabled="!checkedList.length"
                        class="btn btn-accent btn-sm"
                >
                    Отправить отчеты
                </button>
            </div>
        </div>
        <div class="card-header card-header-divider justify-content-between course-control">
            <div class="flex align-item-center course-control__btns flex-1">
                <custom-select-2
                        :inputWidth="100"
                        v-model="currentCourse"
                        name="courseName"
                        :options="['Выберите курс', ...courseOptions]"
                />
                <button
                        @click="giveCourse"
                        :disabled="!checkedList.length"
                        class="btn btn-accent btn-sm"
                >
                    Выдать курс
                </button>
                <button
                        @click="takeCourse"
                        :disabled="!checkedList.length"
                        class="btn btn-w btn-sm"
                >
                    Забрать курс
                </button>
            </div>
            <div class="select-count">
                <custom-select
                        :labelWidth="40"
                        name="rowsCount"
                        v-model="countOnPage"
                        label="Кол-во пользователей на странице"
                        :options="[10, 15, 20, 50, 75]"
                />
            </div>
        </div>
        <div class="card-header card-header-divider justify-content-betweencourse-control">
            <div class="flex align-item-center course-control__btns flex-1">
                <button
                        @click="giveTrajectory"
                        :disabled="!checkedList.length"
                        class="btn btn-accent btn-sm"
                >
                    Выдать траекторию
                </button>
                <button
                        @click="takeTrajectory"
                        :disabled="!checkedList.length"
                        class="btn btn-w btn-sm"
                >
                    Забрать траекторию
                </button>
            </div>
        </div>
        <div class="card-body">
            <button
                    @click="showTransferPopup"
                    class="btn btn-accent btn-sm quota-btn"
            >
                Передать квоты в другую группу
            </button>
            <quota-tables
                    v-if="groupQuotas.test_passings && groupQuotas.course_passings"
                    :quota="groupQuotas"
                    :courseOptions="courseOptions"
                    :testOptions="testOptions"
                    :trajectoriesOptions="trajectoriesOptions"
            />
        </div>

        <div class="card-body">
            <div class="flex quota-tables align-item-center flex-column">
                <div class="table-sm-container w-full">
                    <div
                            v-if="!showFilters"
                            class="hide-btn show"
                            @click="showFilters = true"
                    >
                        Показать фильтры <i class="fal fa-angle-down"></i>
                    </div>
                    <div v-else class="hide-btn hide" @click="showFilters = false">
                        Скрыть фильтры <i class="fal fa-angle-up"></i>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="showFilters">
            <div class="card-header justify-content-start head-sort">
                <custom-input
                        :delay="800"
                        name="staff-passings-sort"
                        placeholder="Имя"
                        label="Поиск:"
                        cleanable
                        :column="columnApi"
                        v-model="searchParams.searchField"
                />
                <custom-select-2
                        v-model="searchParams.searchPosition"
                        name="position"
                        label="Уровень должности:"
                        :options="positionOptions"
                />
            </div>
            <div class="card-header justify-content-start head-sort">
                <custom-select-2
                        v-model="searchParams.searchStatus"
                        name="status"
                        label="Статус:"
                        :options="statusOptions"
                />
                <custom-select-2
                        v-model="searchParams.searchReport"
                        name="report"
                        label="Тип отчета:"
                        :options="testTextOptions"
                />
                <custom-select2
                        label="Курс"
                        v-model="searchParams.searchExistCourse"
                        name="existCourse"
                        :options="['Не выбрано', ...courseTextOptions]"
                />
                <button @click="clearAllSearch" class="btn btn-w reset-btn">
                    Очистить
                </button>
            </div>
            <div class="card-header justify-content-start">
                <custom-checkbox
                        v-model="searchParams.searchCourse"
                        :label="'Не имеет курсов'"
                        :checked="searchParams.searchCourse"
                />
                <custom-checkbox
                        v-model="searchParams.searchAllowReport"
                        :label="'Не отправлен отчет'"
                        :checked="searchParams.searchAllowReport"
                />
                <custom-checkbox
                        v-model="searchParams.searchReadyForTest"
                        :label="'Готов к тестированию'"
                        :checked="searchParams.searchReadyForTest"
                />
            </div>
        </div>

        <div class="flex w-full justify-content-end">
            <transition name="fade">
                <div v-if="checkedList.length" class="selected-count">
                    Выделено: {{ checkedList.length }}
                </div>
            </transition>
        </div>
        <div class="card-body" v-if="group">
            <ag-grid-vue
                    :columnDefs="columnDefs.value"
                    :rowData="rowData"
                    :rowHeight="70"
                    rowSelection="multiple"
                    :headerHeight="90"
                    :enableCellTextSelection="true"
                    :ensureDomOrder="true"
                    :pagination="true"
                    :paginationPageSize="countOnPage"
                    :defaultColDef="defaultColDef"
                    :suppressRowHoverHighlight="true"
                    :suppressPaginationPanel="true"
                    :suppressRowClickSelection="true"
                    :unSortIcon="true"
                    :alwaysMultiSort="true"
                    :suppressRowTransform="true"
                    @row-selected="onRowSelected"
                    style="width: 100%"
                    domLayout="autoHeight"
                    @grid-ready="onGridReady"
                    :isExternalFilterPresent="isExternalFilterPresent"
                    :doesExternalFilterPass="doesExternalFilterPass"
                    @filterChanged="filterChanged"
                    suppressSizeToFit="true"
            ></ag-grid-vue>

            <table-pagination
                    :gridApi="gridApi"
                    :totalPages="gridApi ? gridApi.paginationGetTotalPages() : 0"
                    :countOnPage="countOnPage"
                    :activeFilter="activeFilter"
            />
        </div>
        <div v-else class="table-preloader">
            <preloader/>
        </div>
    </div>
    <course-popup/>
    <transfer-quota-to-group-popup
            :courseOptions="courseOptions"
            :testOptions="testOptions"
            :trajectoriesOptions="trajectoriesOptions"
    />
</template>

<script>
import {
    computed,
    onBeforeMount,
    reactive,
    ref,
    watch,
} from "@vue/runtime-core";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import {notify} from "@kyvg/vue3-notification";
import {AgGridVue} from "ag-grid-vue3";
import "ag-grid-community/dist/styles/ag-grid.css";
import CoursePopup from "@/feature/group-control/popups/CoursePopup.vue";
import EditRenderer from "@/components/Tables/CellRenderers/EditRenderer.vue";
import ActionsRenderer from "@/components/Tables/CellRenderers/ActionsRenderer.vue";
import ClassRenderer from "@/components/Tables/CellRenderers/ClassRenderer.vue";
import StatusRenderer from "@/components/Tables/CellRenderers/StatusRenderer.vue";
import CustomSelect from "@/components/Forms/Fields/CustomSelect.vue";
import CustomCheckbox from "@/components/Forms/Fields/CustomCheckbox.vue";
import Preloader from "@/components/Technical/Preloader.vue";
import CustomSelectFilter from "@/components/Tables/FiltersComponents/CustomSelectFilter.vue";
import useQuotaCount from "@/mixins/useQuotaCount";
import GivingTestsMini from "@/feature/group-control/popups/GivingTestsMini.vue";
import QuotaTables from "@/feature/group-control/QuotaTables.vue";
import AuthorizationRenderer from "@/components/Tables/CellRenderers/AuthorizationRenderer.vue";
import CustomDateFilter from "@/components/Tables/FiltersComponents/CustomDateFilter.vue";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import ControlVisibleColumns from "@/components/OtherElements/ControlVisibleColumns.vue";
import TransferQuotaToGroupPopup from "@/feature/group-control/popups/TransferQuotaToGroupPopup.vue";
import {getTestStatus} from "./../utils/groupPassingUtils";
import reportTranslate from "@/mixins/dictionaries/reportTranslate";
import groupPassingsColumns from "./groupPassingsColumns";
import useCheckRights from "../../../mixins/useCheckRights";

export default {
    name: "group-passings-table",
    components: {
        CustomInput,
        CustomSelect2,
        CoursePopup,
        AgGridVue,
        EditRenderer,
        ActionsRenderer,
        ClassRenderer,
        StatusRenderer,
        CustomSelect,
        CustomCheckbox,
        Preloader,
        CustomSelectFilter,
        GivingTestsMini,
        QuotaTables,
        AuthorizationRenderer,
        CustomDateFilter,
        TablePagination,
        ControlVisibleColumns,
        TransferQuotaToGroupPopup,
    },
    setup() {
        const store = useStore(),
            route = useRoute(),
            router = useRouter(),
            gridApi = ref(null),
            columnApi = ref(null),
            columnsName = ref(null),
            {getCourseListGroup, getTestList, getTrajectoriesList} = useQuotaCount(),
            {getRight} = useCheckRights();

        const onGridReady = (params) => {
            gridApi.value = params.api;
            columnApi.value = params.columnApi;
            columnsName.value = "groupPassingColumns";
        };

        const countOnPage = ref(localStorage.getItem("countOnPagePassings") || 10),
            searchParams = reactive({
                searchField: "",
                searchStatus: "Не выбрано",
                searchPosition: "Не выбрано",
                searchReport: "Не выбрано",
                searchCourse: false,
                searchAllowReport: false,
                searchReadyForTest: false,
                searchExistCourse: "Не выбрано",
            }),
            checkedList = ref([]),
            isPreloader = ref(false),
            positionOptions = ref([]),
            showFilters = ref(false),
            activeFilter = ref(false),
            statusOptions = [
                "Не выбрано",
                "Тест не выдан",
                "Тест не пройден",
                "В процессе прохождения",
                "Тест завершен",
            ],
            courseOptions = ref([]),
            testOptions = ref([]),
            trajectoriesOptions = ref([]),
            currentCourse = ref("Выберите курс");
        const positionList = computed(() => store.state.test.positionList),
            passingsCount = computed(() => store.state.test.passingsCount),
            group = computed(() => store.state.group.group.group),
            groupQuotas = computed(() => store.state.group.groupQuotas),
            courseTextOptions = computed(() =>
                courseOptions.value.map((course) => course.text)
            ),
            testTextOptions = computed(() =>
                testOptions.value.map((test) => test.text)
            ),
            rowData = computed(() => {
                return group.value.users.map((staff) => {
                    return {
                        id: staff.id,
                        name: staff.name,
                        position: staff.position,
                        role: staff.role_russian,
                        status: getTestStatus(staff),
                        class: [staff.course_passings, staff.trajectories],
                        edit: staff.id,
                        onlineFrom: staff.online_from,
                        reportLink: staff.last_test_passing
                            ? staff.last_test_passing.report_link
                            : "",
                        passingDate: staff.last_success_test_passing_date,
                        sendPassingDate: staff.last_test_passing
                            ? staff.last_test_passing.activated_at
                            : "",
                        code: staff.last_test_passing ? staff.last_test_passing.code : "",
                        report: staff.last_test_passing
                            ? reportTranslate[staff.last_test_passing.report_type]
                            : "",
                        allowViewReport: staff.last_test_passing
                            ? staff.last_test_passing.allow_view_report
                            : null,
                        readyForTest: staff.ready_to_new_test,
                    };
                });
            });

        const columnDefs = reactive(groupPassingsColumns);
        const defaultColDef = {
            suppressMovable: true,
            flex: 1,
            autoHeight: true,
            resizable: true,
            wrapText: true
        };

        const onRowSelected = (e) => {
            const id = e.node.data.id;
            const isInclude = checkedList.value.includes(id);
            if (e.node.isSelected() && !isInclude) {
                checkedList.value.push(id);
                return;
            }
            if (!e.node.isSelected() && isInclude) {
                checkedList.value = checkedList.value.filter(
                    (currentId) => currentId != id
                );
            }
        };

        const takePassing = () => {
            if (
                !group.value.users.every(
                    (staff) =>
                        !checkedList.value.includes(staff.id) ||
                        (staff.last_test_passing &&
                            staff.last_test_passing.status != "finished")
                )
            ) {
                showNotification(
                    "Нельзя забрать у сотрудников, у которых нет теста или тест уже пройден"
                );
            } else {
                store
                    .dispatch("group/takeStaffPassings", {
                        id: group.value.id,
                        staffIds: checkedList.value,
                        count: checkedList.value.length,
                    })
                    .then(() => {
                        store.dispatch("group/getGroupQuotas", route.params.id);
                        recoverChecks();
                    });
            }
        };
        const giveTrajectory = () => {
                store
                    .dispatch("group/giveStaffTrajectory", {
                        id: group.value.id,
                        staffIds: checkedList.value,
                        count: checkedList.value.length
                    })
                    .then(() => {
                        store.dispatch("group/getGroupQuotas", route.params.id);
                        recoverChecks();
                    })
            },
            takeTrajectory = () => {
                store
                    .dispatch("group/takeStaffTrajectory", {
                        id: group.value.id,
                        staffIds: checkedList.value,
                        count: checkedList.value.length,
                    })
                    .then(() => {
                        store.dispatch("group/getGroupQuotas", route.params.id);
                        recoverChecks();
                    })
            }
        const giveCourse = () => {
                if (currentCourse.value === "Выберите курс") {
                    showNotification("Выберите курс");
                } else
                    store
                        .dispatch("group/giveStaffCourses", {
                            id: group.value.id,
                            staffIds: checkedList.value,
                            course: currentCourse.value,
                            count: checkedList.value.length,
                            name: courseOptions.value.find(
                                (course) => course.id === currentCourse.value
                            ),
                            source: courseOptions.value.find(
                                (course) => course.id === currentCourse.value
                            ).source
                        })
                        .then(() => {
                            store.dispatch("group/getGroupQuotas", route.params.id);
                            recoverChecks();
                        });
            },
            takeCourse = () => {
                if (currentCourse.value === "Выберите курс") {
                    showNotification("Выберите курс");
                } else
                    store
                        .dispatch("group/takeStaffCourses", {
                            id: group.value.id,
                            staffIds: checkedList.value,
                            course: currentCourse.value,
                            count: checkedList.value.length,
                        })
                        .then(() => {
                            store.dispatch("group/getGroupQuotas", route.params.id);
                            recoverChecks();
                        });
            }
        const remindAboutTest = () => {
            store
                .dispatch("group/remindAboutTest", {
                    id: group.value.id,
                    staffIds: checkedList.value,
                })
                .then(() => {
                    clearChecked();
                });
        };
        const sendReports = () => {
            store
                .dispatch("group/allowToViewReport", {
                    id: group.value.id,
                    staffIds: checkedList.value,
                })
                .then(() => {
                    recoverChecks();
                });
        };
        const showNotification = (text, type = "warning") => {
            notify({
                title: text,
                type,
            });
        };

        const clearAllSearch = () => {
            searchParams.searchField = "";
            searchParams.searchStatus = "Не выбрано";
            searchParams.searchPosition = "Не выбрано";
            searchParams.searchReport = "Не выбрано";
            searchParams.searchCourse = false;
            searchParams.searchAllowReport = false;
            searchParams.searchReadyForTest = false;
            searchParams.searchExistCourse = "Не выбрано";
            gridApi.value.setFilterModel(null);
        };

        const isExternalFilterPresent = () => {
            const isPresent =
                searchParams.searchCourse ||
                searchParams.searchAllowReport ||
                searchParams.searchReadyForTest ||
                searchParams.searchExistCourse !== "Не выбрано";
            if (isPresent) activeFilter.value = !activeFilter.value;
            return isPresent;
        };
        const doesExternalFilterPass = (node) => {
            const isPass =
                (!searchParams.searchCourse || !node.data.class.length) &&
                (!searchParams.searchAllowReport ||
                    (!node.data.allowViewReport &&
                        node.data.status === "Тест завершен")) &&
                (!searchParams.searchReadyForTest || node.data.readyForTest) &&
                (searchParams.searchExistCourse === "Не выбрано" ||
                    node.data.class.find(
                        (course) => course.name === searchParams.searchExistCourse
                    ));
            return isPass;
        };

        const filterChanged = () => {
            activeFilter.value = !activeFilter.value;
        };

        const clearChecked = () => {
            gridApi.value.deselectAll();
            checkedList.value = [];
        };

        const recoverChecks = () => {
            gridApi.value.forEachNode((node) =>
                node.setSelected(checkedList.value.includes(node.data.id))
            );
        };

        const getReports = () => {
            const codes = [];
            gridApi.value.forEachNode((node) => {
                if (node.data.status == "Тест завершен" || node.data.reportLink) {
                    codes.push(node.data.code);
                }
            });
            if (!codes.length) {
                notify({
                    type: "warning",
                    title: "Нет доступных для скачивания отчетов",
                });
                return;
            }
            store.dispatch("group/getReportsArchive", {
                group: group.value.id,
                name: group.value.name,
                codes,
            });
        };

        onBeforeMount(() => {
            store.dispatch("group/getGroup", route.params.id).then(() => {
                columnDefs.value[columnDefs.value.length - 2].cellRendererParams = {
                    ...columnDefs.value[columnDefs.value.length - 2].cellRendererParams,
                    group: group.value.id,
                };
            });
            store.dispatch("group/getGroupQuotas", route.params.id).then(() => {
                courseOptions.value = getCourseListGroup(groupQuotas.value.course_passings);
                testOptions.value = getTestList(groupQuotas.value.test_passings);
                trajectoriesOptions.value = getTrajectoriesList(groupQuotas.value.trajectories);
                columnDefs.value[columnDefs.value.length - 2].cellRendererParams = {
                    ...columnDefs.value[columnDefs.value.length - 2].cellRendererParams,
                    testOptions: testOptions.value,
                };
            });
            store.dispatch("test/getPositionList").then(() => {
                positionOptions.value = positionList.value.map(
                    (position) => position.text
                );
                positionOptions.value.unshift("Не выбрано");
            });
        });

        watch(searchParams, () => {
            if (
                searchParams.searchField.length >= 3 ||
                searchParams.searchStatus !== "Не выбрано" ||
                searchParams.searchPosition !== "Не выбрано" ||
                searchParams.searchReport !== "Не выбрано"
            ) {
                gridApi.value.setQuickFilter(
                    `${
                        searchParams.searchField.length >= 3 ? searchParams.searchField : ""
                    } ${
                        searchParams.searchStatus !== "Не выбрано"
                            ? searchParams.searchStatus
                            : ""
                    } ${
                        searchParams.searchPosition !== "Не выбрано"
                            ? searchParams.searchPosition
                            : ""
                    } ${
                        searchParams.searchReport !== "Не выбрано"
                            ? searchParams.searchReport
                            : ""
                    }`
                );
            } else {
                gridApi.value.setQuickFilter(null);
            }
            gridApi.value.onFilterChanged();
            activeFilter.value = !activeFilter.value;
        });

        watch(countOnPage, () => {
            localStorage.setItem("countOnPagePassings", countOnPage.value);
        });

        const showTransferPopup = () => {
            store.commit("ui/showTransferQuotasPopup", true);
        };

        const url = process.env.VUE_APP_FRONT;

        return {
            route,
            router,
            countOnPage,
            checkedList,
            isPreloader,
            statusOptions,
            searchParams,
            takePassing,
            positionOptions,
            positionList,
            clearAllSearch,
            passingsCount,
            group,
            testTextOptions,
            showFilters,
            remindAboutTest,
            groupQuotas,
            getRight,
            trajectoriesOptions,
            courseOptions,
            testOptions,
            courseTextOptions,
            currentCourse,
            activeFilter,

            giveCourse,
            takeCourse,
            sendReports,
            getReports,
            giveTrajectory,
            clearChecked,
            takeTrajectory,
            columnDefs,
            rowData,
            defaultColDef,
            onRowSelected,
            gridApi,
            onGridReady,
            columnApi,

            columnsName,

            isExternalFilterPresent,
            doesExternalFilterPass,
            url,

            showTransferPopup,

            filterChanged,
        };
    },
};
</script>

<style lang="scss">
.group-passing-table {
  .ag-body-viewport {
    min-height: 360px;
  }
}
</style>

<style lang="scss" scoped>
.checkbox-cell {
  padding-bottom: 4px;
}

.group-passing-table {
  .control-btns .btn:not(:last-child),
  .action-btn {
    margin-right: 10px;
  }

  .users-count {
    font-size: 15px;
    font-weight: 300;
  }

  .select-count {
    min-width: 345px;
  }

  .reset-btn {
    font-size: 16px;
  }

  .quota-btn {
    margin-bottom: 16px;
  }

  .head-sort:nth-of-type(3) {
    padding-top: 0;
  }

  .hide-btn {
    margin: 0 20px;
  }

  .selected-count {
    margin-right: 40px;
  }

  .btn-accent {
    margin-left: 0;
  }

  .course-control {
    .form-group {
      margin-right: 0 !important;
      width: auto !important;
    }

    &__btns {
      .btn {
        margin-right: 10px;
      }
    }

    @media (max-width: 1440px) {
      flex-direction: column;
      align-items: flex-start;

      .form-group {
        min-width: 380px;
      }
    }

    @media (max-width: 1040px) {
      .form-group {
        min-width: 100% !important;
      }
      &__btns {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;

        .btn {
          margin-top: 15px;
          width: 97%;
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .card-header {
      .form-group:not(:last-child) {
        margin-right: 15px;
      }

      .form-group {
        min-width: 380px;
      }
    }
  }

  @media (max-width: 1660px) {
    .head-sort {
      flex-direction: column;
      align-items: flex-start;
    }
    .reset-btn {
      margin-top: 15px;
      margin-left: 0;
    }

    .form-group {
      width: 100%;
    }
  }

  @media (max-width: 1480px) {
    .control-btns {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      button:not(:last-child),
      .action-btn {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    .selected-count {
      margin-top: 15px;
    }
  }

  @media (max-width: 978px) {
    .card-header-divider {
      flex-direction: column;
      align-items: flex-start;
    }
    .select-count {
      min-width: 100%;
      margin-top: 15px;
    }
  }

  @media (min-width: 426px) {
    .hide-btn {
      margin: 0 30px;
    }
  }
}
</style>

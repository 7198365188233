<template>
  <transition name="fade">
    <div
      v-if="showTransferQuotasPopup"
      class="popup popup__backdrop"
      @mousedown.self="closePopup"
    >
      <div class="popup__content">
        <div class="transfer-popup card">
          <div class="transfer-popup__header card-header card-header-divider">
            <div>Передача квот</div>
            <button @click="closePopup" class="transfer-popup__close-btn">
              <svg
                fill="#3d3d3d"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 50"
                width="50px"
                height="50px"
              >
                <path
                  d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"
                />
              </svg>
            </button>
          </div>
          <div class="transfer-popup__body">
            <div class="instruction-block">
              <div class="instruction-block__label">
                1. Посмотрите, сколько в группе доступно квот
              </div>
              <quota-tables
                v-if="groupQuotas.test_passings && groupQuotas.course_passings && groupQuotas.trajectories"
                :quota="groupQuotas"
                :courseOptions="courseOptions"
                :testOptions="testOptions"
                :trajectoriesOptions="trajectoriesOptions"
              />
            </div>
            <div class="instruction-block">
              <div class="instruction-block__label">
                2. Выберите нужную группу для выдачи квот
              </div>
              <div
                class="flex align-item-center quota-params__line flex-column"
              >
                <custom-select-2
                  v-model="selectedGroup"
                  name=""
                  :options="['Выберите группу', ...groupOptions]"
                  class="flex-1"
                  :inputWidth="98"
                />
              </div>
            </div>
            <div
              class="
                flex
                group-quota__params
                quota-params
                instruction-block
                flex-column
              "
            >
              <div class="instruction-block__label">
                3. Выберите тип отчета или курс и укажите их количество. Нажмите
                “Передать тесты” или “Передать курсы”.
              </div>
              <div
                class="flex align-item-center quota-params__line flex-column"
              >
                <custom-select-2
                  name="testType"
                  v-model="quota.reportType"
                  :options="[
                    { id: '0', text: 'Выберите тип отчета' },
                    ...testOptions,
                  ]"
                  class="flex-1"
                  :inputWidth="98"
                />
                <div class="w-full quota-params__btn">
                  <custom-input
                    name="testsCount"
                    type="number"
                    v-model="quota.testCount"
                    class="flex-1"
                    label="Количество тестов"
                    :minValue="0"
                  />
                </div>
                <div class="flex justify-content-end w-full quota-params__btn">
                  <button @click="giveTestsToGroup" class="btn btn-accent">
                    Передать тесты
                  </button>
                </div>
              </div>
              <div
                class="flex align-item-center quota-params__line flex-column"
              >
                <custom-select-2
                  v-model="quota.course"
                  name="courseCode"
                  :options="['Выберите курс', ...courseOptions]"
                  class="flex-1"
                  :inputWidth="98"
                />
                <div class="w-full quota-params__btn">
                  <custom-input
                    v-model="quota.courseCount"
                    name="coursesCount"
                    type="number"
                    label="Количество курсов"
                    class="flex-1"
                    :minValue="0"
                  />
                </div>
                <div class="flex justify-content-end w-full quota-params__btn">
                  <button @click="giveCoursesToGroup" class="btn btn-accent">
                    Передать курсы
                  </button>
                </div>
              </div>
                <div
                        class="flex align-item-center quota-params__line flex-column"
                >
                    <div class="w-full quota-params__btn">
                        <custom-input
                                v-model="quota.trajectoriesCount"
                                name="trajectoriesCount"
                                type="number"
                                label="Количество траекторий"
                                class="flex-1"
                                :minValue="0"
                        />
                    </div>
                    <div class="flex justify-content-end w-full quota-params__btn">
                        <button @click="giveTrajectoriesToGroup" class="btn btn-accent">
                            Передать траектории
                        </button>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {computed, onBeforeMount, reactive, ref, watch} from "@vue/runtime-core";
import { useStore } from "vuex";
import QuotaTables from "@/feature/group-control/QuotaTables.vue";
import GroupQuotaRow from "@/feature/group-control/GroupQuotaRow.vue";
import CustomInput from "../../../components/Forms/Fields/CustomInput.vue";
import CustomSelect2 from "../../../components/Forms/Fields/CustomSelect2.vue";
import { useRoute } from "vue-router";
import useQuotaManipulations from "../../../mixins/quotas/useQuotasManipulations";
import { notify } from "@kyvg/vue3-notification";
import useErrors from "../../../mixins/useErrors";
export default {
  name: "transfer-quota-popup",
  components: { QuotaTables, GroupQuotaRow, CustomInput, CustomSelect2 },
  props: {
    courseOptions: {
      type: Array,
    },
    testOptions: {
      type: Array,
    },
    trajectoriesOptions: {
      type: Array,
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { id } = useRoute().params;
    const { takeCourses, takeTests, giveCourses, giveTests, giveTrajectories, takeTrajectories } =
      useQuotaManipulations();
    const { customUIError } = useErrors();
    const quota = reactive({
        reportType: "0",
        course: "Выберите курс",
        testCount: 0,
        courseCount: 0,
        trajectoriesCount: 0,
      }),
      selectedGroup = ref(null);
    const closePopup = () => {
      store.commit("ui/showTransferQuotasPopup", false);
    };
    const showTransferQuotasPopup = computed(
      () => store.state.ui.showTransferQuotasPopup
    );
    const groupQuotas = computed(() => store.state.group.groupQuotas);
    const profile = computed(() => store.state.profile.profile);
    const groups = computed(() => store.state.group.groups);
    const groupsWithoutCurrent = computed(() =>
      groups.value
        ? groups.value.filter((group) => group.id !== Number(id))
        : []
    );
    const groupOptions = ref([]);
    onBeforeMount(() => {
      store.dispatch("group/getGroups").then(() => {
        groupOptions.value = groupsWithoutCurrent.value.map((group) => {
          return { id: group.id, text: group.name };
        });
      });
        if (profile.value && profile.value.company) {
            store
                .dispatch("company/getCompanyQuota", profile.value.company.id)
        }
    });
    watch(profile, () => {
        if (profile.value && profile.value.company)
            store
                .dispatch("company/getCompanyQuota", profile.value.company.id)
      })

    const giveCoursesToGroup = () => {
      if (!selectedGroup.value || selectedGroup.value === "Выберите группу") {
        notify({ type: "warning", title: "Не выбрана группа" });
        return;
      }
      takeCourses(quota, id)
        .then(() => {
          giveCourses(quota, Number(selectedGroup.value));
          store.dispatch("group/getGroupQuotas", route.params.id);
        })
        .catch(customUIError);
    };
      const giveTrajectoriesToGroup = () => {
          if (!selectedGroup.value || selectedGroup.value === "Выберите группу") {
              notify({ type: "warning", title: "Не выбрана группа" });
              return;
          }
          takeTrajectories(quota, id)
              .then(() => {
                  giveTrajectories(quota, Number(selectedGroup.value));
                  store.dispatch("group/getGroupQuotas", route.params.id);
              })
              .catch(customUIError);
      };
    const giveTestsToGroup = () => {
      if (!selectedGroup.value || selectedGroup.value === "Выберите группу") {
        notify({ type: "warning", title: "Не выбрана группа" });
        return;
      }
      takeTests(quota, id)
        .then(() => {
          giveTests(quota, Number(selectedGroup.value));
          store.dispatch("group/getGroupQuotas", route.params.id);
        })
        .catch(customUIError);
    };

    return {
      closePopup,
      showTransferQuotasPopup,
      groupQuotas,
      quota,
      selectedGroup,
      groupOptions,

      giveCoursesToGroup,
      giveTestsToGroup,
      giveTrajectoriesToGroup
    };
  },
};
</script>

<style lang="scss" scoped>
.transfer-popup {
  background-color: white;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  border-radius: 0;

  &__header {
    font-size: 26px;
    font-weight: 400;
    padding: 24px 0 14px;
    margin: 0 40px;
    line-height: 1.738;
    flex-direction: row;
  }

  &__close-btn {
    height: 45px;
    width: 40px;
    svg {
      max-width: 100%;
    }

    &:hover svg {
      fill: var(--hover-color);
    }
  }

  &__body {
    padding: 40px 40px;
    overflow-x: auto;
  }
  .btn {
    margin-right: 20px;
  }

  @media (max-width: 425px) {
    &__header {
      margin: 0 20px;
    }
    &__body {
      padding: 0 20px;
    }
  }
}
</style>